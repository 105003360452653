import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomSectionTitleService {
  private readonly customTitle = new Subject<string>();

  constructor() {}

  setCustomTitle(newTitle: string): void {
    this.customTitle.next(newTitle);
  }

  getCustomTitle(): Observable<string> {
    return this.customTitle.asObservable();
  }
}
